<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="6">
        <el-date-picker
          v-model="dateRange"
          value-format="x"
          type="datetimerange"
          @change="changeDate"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        >
        </el-date-picker>
      </el-col>

      <el-col :span="4">
        <el-input
          placeholder="邀请者姓名"
          v-model="query.inviterName"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-input
          placeholder="邀请者电话"
          v-model="query.inviterPhone"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-input
          placeholder="受邀者姓名"
          v-model="query.inviteeName"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-input
          placeholder="受邀者电话"
          v-model="query.inviteePhone"
        ></el-input>
      </el-col>

     

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
        <el-button type="primary" @click="doExport">导出邀请记录</el-button>
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="邀请人" prop="inviterName"></el-table-column>
      <el-table-column label="邀请人电话" prop="inviterPhone"></el-table-column>
      <el-table-column label="被邀请人" prop="inviteeName"></el-table-column>
      <el-table-column
        label="被邀请人电话"
        prop="inviteePhone"
      ></el-table-column>
      <el-table-column label="邀请时间" prop="createTime">
        <template #default="scope">
          <span>{{
            this.$filter.dateFormat(new Date(scope.row.createTime))
          }}</span>
        </template>
      </el-table-column>
      
      <el-table-column label="订阅奖励" prop="firstReward">
        <template #default="scope">{{ scope.row.firstReward / 100 }}</template>
      </el-table-column>

      <el-table-column label="续费奖励" prop="renewalReward">
        <template #default="scope">{{ scope.row.renewalReward / 100 }}</template>
      </el-table-column>
      
    </el-table>

    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>

<script>
import {
  fetchUserInviteRecord,
  exportUserinviteList,
} from "@/api/batteryMan.js";
import moment from "moment";

export default {
  data() {
    let startTime = moment()
      .startOf("day")
      .subtract(1, "month")
      .format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");

    return {
      dateRange: [startTime, endTime],
      dataSource: [],
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {
        startTime: "",
        endTime: ""
      },
      total: 0,
    };
  },
  methods: {
    changeDate(e) {
      if (!this.validatenull(e)) {
        this.query.startTime = moment(new Date(parseInt(e[0]))).startOf("day").format("YYYY-MM-DD HH:mm:ss");
        this.query.endTime =  moment(new Date(parseInt(e[1]))).endOf("day").format("YYYY-MM-DD HH:mm:ss");
        this.dateRange = e;
      } else {
        this.query.startTime = "";
        this.query.endTime = "";
      }
      this.getList(1);
    },

    doExport() {
      if (!this.query.startTime) {
        this.$message.error("请选择时间段");
        return;
      }
      exportUserinviteList(this.query).then((res) => {
        this.util.apiDownLoadFile(
          res.data,
          "application/vnd.ms-excel",
          `${this.query.startTime}-${this.query.endTime}订单数据.xlsx`
        );
      });
    },
    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }

      fetchUserInviteRecord({ ...this.query, ...this.pages }).then((res) => {
        this.dataSource = res.data.data.records;
        this.total = res.data.data.total;
      });
    },
  },

  mounted() {
    let phone = this.$router.currentRoute._value.query.phone;
    if (phone) {
      this.query.inviterPhone = phone;
    }
    this.getList();
  },
};
</script>
